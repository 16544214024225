'use client';

import * as Sentry from '@sentry/nextjs';
import Image from 'next/image';
import { useEffect } from 'react';
export default function GlobalError({
  error
}: {
  error: any;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <div className="flex flex-col items-center justify-center h-screen">
          <Image src="/img/compass_logo.svg" alt="CompassCx Logo" width={150} height={100} data-sentry-element="Image" data-sentry-source-file="global-error.tsx" />
          <p className="m-2">{error.publicMessage ? error.publicMessage : 'Ha ocurido un error, intenta de nuevo.'}</p>
        </div>
      </body>
    </html>;
}